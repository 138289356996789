<template>
  <!-- Component: organisms/note-create -->
  <form action="#note-create" class="form-word" v-on:submit.prevent="createNotificationWord">
    <p class="note-create__title">New Word </p>

    <div class="field">
        <div  class="error-message" v-if="createError">{{ createError }}</div>
      <label class="label" for="note-title">Word</label>
      <div class="control">
        <input class="input" type="text" minlength="2"  placeholder="Word" id="note-title" v-model.trim="text" required>
      </div>
    </div>

    <div class="field">
      <button class="button is-primary" type="submit" :disabled="createPending ? 'disabled' : undefined">Save Word</button>
      <preloader v-if="createPending"/>
    </div>

  </form>
  <!-- End Component: organisms/note-create -->
</template>

<script>
import {  ref } from "vue";
import TagInput from "./tag-input";
import Preloader from "./preloader";

export default {
  name: 'notification-keywords-create',
  components: {Preloader, TagInput},
  props: [],
  emits: ['created'],
  setup(props, {emit}) {
    const createPending = ref(false);
    const createError = ref(null);
    const notificationWords = ref([]);
    const text = ref('');
    const blackList = ref(['i', 'me', 'my', 'myself', 'we', 'our', 'ours', 'ourselves', 'you', "you're", "you've", "you'll", "you'd", 'your', 'yours', 'yourself', 'yourselves', 'he', 'him', 'his', 'himself', 'she', "she's", 'her', 'hers', 'herself', 'it', "it's", 'its', 'itself', 'they', 'them', 'their', 'theirs', 'themselves', 'what', 'which', 'who', 'whom', 'this', 'that', "that'll", 'these', 'those', 'am', 'is', 'are', 'was', 'were', 'be', 'been', 'being', 'have', 'has', 'had', 'having', 'do', 'does', 'did', 'doing', 'a', 'an', 'the', 'and', 'but', 'if', 'or', 'because', 'as', 'until', 'while', 'of', 'at', 'by', 'for', 'with', 'about', 'against', 'between', 'into', 'through', 'during', 'before', 'after', 'above', 'below', 'to', 'from', 'up', 'down', 'in', 'out', 'on', 'off', 'over', 'under', 'again', 'further', 'then', 'once', 'here', 'there', 'when', 'where', 'why', 'how', 'all', 'any', 'both', 'each', 'few', 'more', 'most', 'other', 'some', 'such', 'no', 'nor', 'not', 'only', 'own', 'same', 'so', 'than', 'too', 'very', 's', 't', 'can', 'will', 'just', 'don', "don't", 'should', "should've", 'now', 'd', 'll', 'm', 'o', 're', 've', 'y', 'ain', 'aren', "aren't", 'couldn', "couldn't", 'didn', "didn't", 'doesn', "doesn't", 'hadn', "hadn't", 'hasn', "hasn't", 'haven', "haven't", 'isn', "isn't", 'ma', 'mightn', "mightn't", 'mustn', "mustn't", 'needn', "needn't", 'shan', "shan't", 'shouldn', "shouldn't", 'wasn', "wasn't", 'weren', "weren't", 'won', "won't", 'wouldn', "wouldn't"]);

    const createNotificationWord = () => {
        if ( text.value.length >= 2 ) {
            if (blackList.value.includes(text.value.toLowerCase())){
                errorMessage('word in  Black list ')
            }else {
                notificationWords.value.push(text.value)
                emit('created', text.value)
                text.value = null
            }
        } else {
            errorMessage('Error Occurred')
        }
     };

    const errorMessage = (textError) => {
        createError.value = textError
        setTimeout(()=> {createError.value = null}, 1500)
    }

    return {
        createNotificationWord,
        text,
        createError,
        createPending,
        notificationWords,
        blackList,
        errorMessage,
    };
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/mixins/_bemify.scss";

@include block('note-create') {
    width: 85%;
  @include element('title') {
    font-size: 1.5rem;
    font-weight: 600;
    font-family: $header;
    margin-top: 15px
  }
}
button.is-primary {
  font-weight: 600;
  font-family: $header;
  margin-top: 15px;
}
.note-create__title {
    text-align: center;
}
.error-message {
    color: red;
    text-transform: uppercase;
}
</style>
